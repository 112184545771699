body {
  scrollbar-face-color: #484441;
  scrollbar-track-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
::-webkit-scrollbar-thumb {
  background: #85919b;
  border-radius: 8px;
  border: 3px solid #ffffff;
  min-height: 150px;
}
::-webkit-scrollbar-thumb:hover {
  background: #abb0b5;
}
::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.MuiOutlinedInput-input
  MuiInputBase-input
  css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
  background: #fff;
}
.collapse.show {
  visibility: visible;
}

.progress-bar {
  background-color: #dbdbdd;
}
.border-bottom {
  border-color: #f3f3f3 !important;
}
.emptycell-none:empty {
  display: none !important;
}

/* input[type="date"]::-webkit-datetime-edit {
  color: transparent;
} */
.tableairp-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  max-height: 500px;
  margin-bottom: 30px;
  border: solid 1px #efefef;
  border-radius: 12px;
}
.tableairp-responsive td,
.tableairp-responsive th {
  white-space: nowrap;
}
.tableairp-responsive thead {
  position: -o-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}
.modal {
  z-index: 1300;
}
.scroll-vhfulls {
  max-height: 100%;
  height: 100%;
}
.MuiTable-root td {
  border: solid 1px #eceef1;
}
.maxw-eliptr {
  word-break: break-all;
  max-width: 130px;
  min-width: 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pagination ul {
  display: flex;
  justify-content: space-between;
  gap: 3px;
  margin-left: 20px;
  padding-left: 0;
}
.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  gap: 12px;
}

.pagination li {
  display: inline-block;
  border: 1px solid rgb(224, 224, 224);
  color: #000;
  cursor: pointer;
  margin-right: 3px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.tableairp-responsiveBooking {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin-bottom: 30px;
  border: solid 1px #efefef;
  border-radius: 12px;
}
.pagination li a {
  padding: 5px;

  color: #000;
  outline: none;
}
.pagination li.active {
  border: 1px solid red;
  outline: none;
}
.lastrow {
  padding-right: 10px;
  background: #fff;
}
.laststick-tr th:last-child {
  background: #f4f6f8;
}
.lastrow,
.laststick-tr th:last-child {
  position: -o-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
}
.booking th {
  line-height: 1rem;
  font-size: 0.95rem;
  font-family: Public Sans, sans-serif;
  font-weight: 600;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(241, 243, 244, 1);
  text-align: left;
  padding: 16px;
  border-bottom: none;
  color: #f5f6f7;
  background-color: #326fa8;
}

.booking td {
  font-size: 0.9rem;
  line-height: 1.5714285714285714;
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  padding: 16px;
  color: #212b36;
}
.technical-errorp .container p {
  max-width: 350px;
  margin: 0 auto;
}
.kytravo-h1 {
  font-weight: 700;
  font-size: 32px;
}
.kytravo-h2 {
  font-weight: 600;
  font-size: 24px;
}
img.technical-thumb {
  width: 320px;
  display: block;
  margin: 0 auto 24px;
  border-radius: 10px;
}
.newAccord {
  box-shadow: none !important;
}

.newAccord .css-mkqcuw-MuiAccordionSummary-expandIconWrapper {
  position: relative;
  top: -10px;
  left: -15px;
}
.accordIcon {
  color: #00ab55;
}
.mySwitch .css-svffmk-MuiSwitch-thumb {
  border: 2px solid #dfdfdf;
}

.city_location {
  background-color: white;
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  z-index: 11;
  border: 1px solid rgb(214, 205, 205);
  border-radius: 10px;
}
.listing_city {
  border-bottom: 1px solid #eee;
  padding: 10px;
  cursor: pointer;
  color: #333;
  font-size: 13px;
}
